





























































import { Vue, Component } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import ProgressBar from 'vuejs-progress-bar'
import { secondsToHMS } from '@/components/Youtube/time'
import { categoryList } from '@/components//Publish/publish'
import AdoriService from '@/services/adori'
import { duration } from 'moment'

@Component({ components: { ProgressBar } })
export default class AutoPublishStepTwo extends Vue {
  @Getter networkId!: string
  @Getter currentPodcast!: any
  @Getter selectedPodcastSetting!: any

  @Action ytModalId!: Function
  @Action ytPodcastModalSetting!: any
  @Action showAutoPublishSetting!: any

  uploadingImage = false
  file!: any
  imageId = ''
  posterImageId = ''
  downloadLoader = false
  interval!: any
  audioStatus = ''

  async mounted() {}
  get podcast() {
    return this.currentPodcast
  }

  get keywords() {
    return this.selectedPodcastSetting.keywords.toString()
  }

  get size() {
    return `${this.selectedPodcastSetting.width}x${this.selectedPodcastSetting.height}`
  }

  get fullDuration() {
    return this.selectedPodcastSetting.fullDuration
  }

  get duration() {
    return secondsToHMS(this.selectedPodcastSetting.endTimeSec - this.selectedPodcastSetting.startTimeSec)
  }

  get publish() {
    return this.selectedPodcastSetting.publish
  }

  get category() {
    let categoryId = parseInt(this.selectedPodcastSetting.category)
    let index = categoryList.findIndex((item) => item.id === categoryId)
    return categoryList[index].title
  }

  get includeVisuals() {
    return this.selectedPodcastSetting.includeVisuals
  }

  get privacy() {
    return this.selectedPodcastSetting.privacy
  }

  get podcastPreviewImage() {
    if (this.imageId) {
      return this.file.url
    } else {
      return this.selectedPodcastSetting.thumbnailSrc
    }
  }

  editPublish() {
    this.showAutoPublishSetting()
  }

  imageClicked() {
    if (this.networkId) {
      this.$store.dispatch('showFileUploader', {
        accept: 'image/*',
        onChange: this.loadImage,
        dontCheck: true,
      })
    } else {
      this.$store.commit('setTriggerLogin')
    }
  }

  async loadImage() {
    const file = this.$store.getters.selectedFile
    if (file) {
      this.uploadingImage = true
      const img: any = await AdoriService.uploadImage(this.networkId, file)
      this.file = img
      this.imageId = img.id
      this.posterImageId = img.id
      this.ytPodcastModalSetting({
        thumbnailSrc: img.url,
        posterImageId: img.id,
      })
      Vue.set(img, 'thumbnailSrc', img.url)
      this.uploadingImage = false
    }
  }
}
