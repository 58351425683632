
































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'

// common components
import TheHeader from '@/components/Common/TheHeader.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import StepperComponent from '@/components/Common/StepperComponent.vue'

import AutoPublishStepOne from '@/components/AutoPublish/AutoPublishStepOne.vue'
import AutoPublishStepTwo from '@/components/AutoPublish/AutoPublishStepTwo.vue'
import TheAudiogramGrid from '@/components/Audiogram/TheAudiogramGrid.vue'
import { feedQueryKeys } from '@/hooks/rssFeeds'
import { youtubeQueryKeys } from '@/hooks/youtube'
import Invalidate from '@/mixins/Invalidate'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: {
    TheHeader,
    TheTitle,
    TheSideNavigation,
    StepperComponent,
    AutoPublishStepOne,
    AutoPublishStepTwo,
    TheAudiogramGrid,
  },
})
export default class AutoPublish extends Mixins(Invalidate) {
  @Getter autoPublishStep!: any
  @Getter networkType!: any
  @Getter networkId!: string
  @Getter showPodcastEnhance!: string
  @Getter selectedPodcastSetting!: any
  @Getter selectedAudiograms: any
  @Getter currentPodcast!: any
  @Getter signinStatus!: any
  @Getter youtubeProfile!: any
  @Getter triggerLogin!: any
  @Getter createdYoutubeFeed!: any
  @Getter isYoutube!: any

  @Action podcastEnhance!: any
  @Action getIntegrationsSummary!: any
  @Action youtubeCategories!: any
  @Action youtubePlaylist!: any
  @Action youtubeSignout!: any
  @Action youtubeAccount!: any
  @Action pushNotification!: any
  @Action autoPublishPodcast!: any
  @Action updateAutoPublish!: any
  @Action getAllAutomations!: any

  filter = 'LANDSCAPE'

  youtubeAuthHandle: any = null
  youtubeAuthWindow: any = null
  youtubeLoader = false

  stepsLabel = [
    { initially: 'Choose Audio Parameters', onSuccess: 'Audio Selected' },
    { initially: 'Choose Publish Settings', onSuccess: 'Published' },
  ]
  loading = false
  PublishText = 'Start Auto Publishing'
  intent = 'AUTO_PUBLISH'
  nextStepDisabled = false
  automated = false

  get publishIcon() {
    return this.youtubeSignInStatus ? '' : 'lock'
  }

  get showHomeLink() {
    return this.networkId ? true : false
  }

  get handleNext() {
    return true
  }

  get podcast() {
    return this.currentPodcast
  }

  get youtubeSignInStatus() {
    return this.ytFeedUid && this.signinStatus[this.ytFeedUid]?.youtube
      ? this.signinStatus[this.ytFeedUid]?.youtube
      : this.signinStatus[this.networkId]?.youtube
      ? this.signinStatus[this.networkId]?.youtube
      : false
  }

  get isAutomationAdded() {
    return get(this.podcast, 'automations.length')
      ? this.podcast.automations.filter((obj: any) => obj.type === 'youtube')[0].type === 'youtube'
      : false
  }

  async created() {
    this.PublishText = this.isAutomationAdded ? 'Update Auto Publishing' : 'Start Auto Publishing'
    window.addEventListener('beforeunload', this.reload)
  }

  changeStep(step: number) {
    this.$store.commit('setAutoPublishStep', step)
    if (this.autoPublishStep === 1) this.$store.commit('clearAutoPublishState')
  }

  done() {
    this.podcastEnhance(false)
  }

  nextStep() {
    this.$store.commit('setAutoPublishStep', this.autoPublishStep + 1)
  }

  previousStep() {
    this.$store.commit('setAutoPublishStep', this.autoPublishStep - 1)
  }

  async setFilter(value: string) {
    this.filter = value
  }

  get ytFeedUid() {
    return get(this.createdYoutubeFeed, 'uid', '') || sessionStorage.getItem('feedUid')
  }

  beforeCreate() {}

  removeOnload() {
    window.removeEventListener('beforeunload', this.reload)
  }

  reload(event: BeforeUnloadEvent) {
    event.preventDefault()
    return (event.returnValue = 'Changes that you made may not be saved')
  }

  beforeDestroy() {
    this.$store.commit('setAutoPublishStep', 1)
    this.podcastEnhance(false)
    this.$store.commit('setCreatedYoutubeFeed', {})
    this.$store.commit('unselectAudiogram')
    window.removeEventListener('beforeunload', this.reload)
  }

  goToHome() {
    this.$router.push('/home')
  }

  async handleBeforePublish() {
    if (this.youtubeSignInStatus) {
      this.handlePublish()
      this.removeOnload()
    } else {
      this.youtubeAuthWindow = window.open('', 'youtubeAuthHandle', 'width=500,height=715')
      this.triggerYoutubeLogin(true)
    }
  }

  @Watch('triggerLogin')
  async handleTriggerLogin() {
    if (this.triggerLogin) {
      this.triggerYoutubeLogin()
    }
  }
  async handlePublish() {
    const payload: any = {
      rssFeedUid: this.ytFeedUid,
      networkId: this.networkId,
      data: {
        videoHeight: this.selectedPodcastSetting.height,
        videoWidth: this.selectedPodcastSetting.width,
        isEnabled: true,
        type: 'youtube',
        preferences: {
          privacy: this.selectedPodcastSetting.privacy,
          playlistId: this.selectedPodcastSetting.playlistId,
          keywords: this.selectedPodcastSetting.keywords,
          ytCategoryId: this.selectedPodcastSetting.category,
        },
      },
    }

    this.selectedPodcastSetting.customTitle && (payload.data['customTitle'] = this.selectedPodcastSetting.customTitle)

    this.selectedPodcastSetting.newDescription &&
      (payload.data['customDescription'] = this.selectedPodcastSetting.newDescription)

    this.selectedPodcastSetting.isYTShorts &&
      (payload.data.preferences['isShorts'] = this.selectedPodcastSetting.isYTShorts)

    this.selectedPodcastSetting.isMadeForKids &&
      (payload.data.preferences['isMadeForKids'] = this.selectedPodcastSetting.isMadeForKids)
    !this.selectedPodcastSetting.fullDuration
      ? (payload.data['defaultDuration'] = this.selectedPodcastSetting.endTimeSec)
      : (payload.data['defaultDuration'] = null)
    this.selectedAudiograms.length
      ? (payload.data['audiogramId'] = this.selectedAudiograms[0])
      : (payload.data['audiogramId'] = null)
    this.selectedPodcastSetting.playlistId && (payload.data['YtplaylistId'] = this.selectedPodcastSetting.playlistId)
    this.isAutomationAdded && (payload['autoPublishId'] = get(this.podcast, 'automations[0].id')),
      this.isAutomationAdded
        ? (await this.updateAutoPublish(payload), this.getAllAutomations({ networkId: this.networkId }))
        : (await this.autoPublishPodcast(payload), this.getAllAutomations({ networkId: this.networkId }))
    this.automated = true
    this.queryClient.invalidateQueries(feedQueryKeys.YTFEED)

    mixpanel.track('Add AutoPublish')
    this.$gtag.event('auto-publish', { action: 'Add podcast to publish automatically' })
    webengage?.track('auto-publish', { data: payload })
  }

  triggerYoutubeLogin(publish = false) {
    this.youtubeLoader = true
    this.youtubeAuthHandle = window.open(`${window.origin}/youtubeTerms`, 'youtubeAuthHandle', 'width=500,height=715')
    const timer = setInterval(async () => {
      if (get(this.youtubeAuthHandle, 'closed', null)) {
        const youtubePayload: any = { networkId: this.networkId }
        this.youtubeSignInStatus && (youtubePayload['showId'] = this.ytFeedUid)

        // Todo: refactor it when optimizing the youtube flow
        this.queryClient.invalidateQueries([youtubeQueryKeys.YOUTUBE_SUMMARY, this.networkId, { showId: undefined }])
        try {
          clearInterval(timer)
          await this.getIntegrationsSummary(youtubePayload)
          if (this.youtubeSignInStatus) {
            await this.youtubeAccount(youtubePayload)
            if (this.youtubeProfile.active) {
              this.autoPublishStep === 2 && sessionStorage.removeItem('youtubeAuth')
              await this.youtubeCategories(youtubePayload)
              await this.youtubePlaylist(youtubePayload)
              publish && (await this.handlePublish())
              this.youtubeLoader = false
              this.PublishText = this.isAutomationAdded ? 'Update Auto Publishing' : 'Start Auto Publishing'
            } else {
              await this.youtubeSignout(youtubePayload)
              await this.getIntegrationsSummary(youtubePayload)
              this.pushNotification({
                text: `Error in connecting to your Youtube account. The reason is  ${this.youtubeProfile.revokeReason
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, function (str: any) {
                    return str.toUpperCase()
                  })}. <a href="https://help.adorilabs.com/en/collections/3509519-adori-video-and-studio">Learn More</a>`,
                type: 'ERROR',
              })
              this.youtubeLoader = false
              this.PublishText = this.isAutomationAdded ? 'Update Auto Publishing' : 'Start Auto Publishing'
            }
          } else {
            this.youtubeLoader = false
            this.PublishText = this.isAutomationAdded ? 'Update Auto Publishing' : 'Start Auto Publishing'
          }
        } catch (e) {
          clearInterval(timer)
          await this.getIntegrationsSummary(youtubePayload)
          this.youtubeLoader = false
          this.PublishText = this.isAutomationAdded ? 'Update Auto Publishing' : 'Start Auto Publishing'
        }
      }
    }, 1500)
  }
}
