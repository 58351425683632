
























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import { Action, Getter } from 'vuex-class'
import isEmpty from 'lodash.isempty'
import get from 'lodash.get'

// common components
import TheToggleSwitch from '@/components/Common/TheToggleSwitch.vue'
import TheToggleButton from '@/components/Common/TheToggleButton.vue'

// constants
import { DurationType } from '@/components/AutoPublish/autopublish'
import { secondsToHMS } from '@/components/Youtube/time'

@Component({ components: { VueSlider, TheToggleSwitch, TheToggleButton } })
export default class AutoPublishStepOne extends Vue {
  // actions
  @Action ytPodcastModalSetting!: any
  @Action podcastEnhance!: any

  // getters
  @Getter networkId!: any
  @Getter autoPublishStep!: number
  @Getter currentPodcast!: any
  @Getter selectedPodcastSetting!: any
  @Getter selectedAudiograms: any

  title: string = ''
  description: string = ''
  keywords: any = []
  categoryId!: number
  privacy: string = ''
  publish: string = ''
  thumbnailSrc: string = ''
  width: number = 1280
  height: number = 720
  beginTime: string = ''
  endTime: string = ''
  isPlaying: boolean = false
  currentTime = 0
  currentAudio!: any
  uploadStatus = false
  youtubeAuthHandle: any = null
  toggleValue = false

  durationType: string = DurationType.COMPLETE
  customDuration = '20'

  // menu for aspect ratio
  get videoResolutionMenu() {
    return [
      {
        name: 'Full HD (1920 x 1080)',
        icon: 'stay_primary_landscape',
        onClick: () => {
          this.width = 1920
          this.height = 1080
        },
      },

      {
        name: 'HD (1280 x 720)',
        icon: 'stay_primary_landscape',
        onClick: () => {
          this.width = 1280
          this.height = 720
        },
      },
      {
        name: 'Full HD (1080 x 1920)',
        icon: 'stay_primary_portrait',
        onClick: () => {
          this.width = 1080
          this.height = 1920
        },
      },
      {
        name: 'HD (720 x 1280)',
        icon: 'stay_primary_portrait',
        onClick: () => {
          this.width = 720
          this.height = 1280
        },
      },
      {
        name: 'Full HD (1080 x 1080)',
        icon: 'crop_square',
        onClick: () => {
          this.width = 1080
          this.height = 1080
        },
      },
      {
        name: 'HD (720 x 720)',
        icon: 'crop_square',
        onClick: () => {
          this.width = 720
          this.height = 720
        },
      },
    ]
  }

  get timeSelectOptions() {
    const hours: any = Array.from(Array(60).keys())

    const minutes = Array.from(Array(60).keys())

    const seconds: any = Array.from(Array(60).keys())

    return {
      hours,
      minutes,
      seconds,
    }
  }

  @Watch('toggleValue')
  handleToggleValue() {
    this.ytPodcastModalSetting({
      fullDuration: !this.toggleValue,
    })
  }

  @Watch('startTimeSec')
  startTimeChanged() {
    if (this.beginTime > this.endTime) {
      this.$store.dispatch('pushNotification', {
        text: 'Clip start cannot be greater than clip end',
        type: 'ERROR',
      })
      this.beginTime = '00:00:00'
      this.ytPodcastModalSetting({
        startTimeSec: 0,
      })
      //   this.setShorts()
    } else if (this.duration === '0s') {
      this.$store.dispatch('pushNotification', {
        text: 'The duration cannot be 0',
        type: 'ERROR',
      })
      this.beginTime = `${new Date(this.selectedPodcastSetting.startTimeSec * 1000).toISOString().substr(11, 8)}`
      this.ytPodcastModalSetting({
        startTimeSec: 0,
      })
    } else {
      this.ytPodcastModalSetting({
        startTimeSec: this.startTimeSec,
      })
    }
  }

  @Watch('endTimeSec')
  endTimeChanged() {
    if (this.endTime < this.beginTime) {
      this.$store.dispatch('pushNotification', {
        text: 'Clip end cannot be less than clip start',
        type: 'ERROR',
      })
      this.endTime = '00:20:00'
      this.ytPodcastModalSetting({
        endTimeSec: this.hmsToSeconds(this.endTime),
      })
    } else if (this.duration === '0s') {
      this.$store.dispatch('pushNotification', {
        text: 'The duration cannot be 0',
        type: 'ERROR',
      })
      this.endTime = new Date(this.selectedPodcastSetting.endTimeSec * 1000).toISOString().substr(11, 8)
      this.ytPodcastModalSetting({
        endTimeSec: this.endTime,
      })
    } else {
      this.ytPodcastModalSetting({
        endTimeSec: this.endTimeSec,
      })
    }
  }

  @Watch('width')
  widthChanged() {
    this.ytPodcastModalSetting({
      width: this.width,
    })
  }
  @Watch('height')
  heightChanged() {
    this.ytPodcastModalSetting({
      height: this.height,
    })
  }

  get clipEnd() {
    return this.endTime
  }

  get clipStart() {
    return this.beginTime
  }

  get duration() {
    let hms = secondsToHMS(this.hmsToSeconds(this.clipEnd) - this.hmsToSeconds(this.clipStart))
    return hms[0] === '-' ? 'Invalid' : hms ? hms : '0s'
  }

  get startTimeSec() {
    return this.convertHHMMSStoSecs(this.beginTime)
  }

  get endTimeSec() {
    return this.convertHHMMSStoSecs(this.endTime)
  }

  get active() {
    let ratio = (this.width / this.height).toFixed(4)
    return ratio == '1.7778' ? 'landscape' : ratio === '0.5625' ? 'portrait' : ratio === '1.0000' ? 'square' : 'custom'
  }

  setActive(size: string) {
    if (size === 'square') {
      this.width = 720
      this.height = 720
    } else if (size === 'landscape') {
      this.width = 1280
      this.height = 720
    } else {
      this.width = 720
      this.height = 1280
    }
  }

  setDuration(type: string) {
    if (type === DurationType.COMPLETE) {
      this.durationType = DurationType.COMPLETE
    } else if (type === DurationType.NOTCOMPLETE) {
      this.durationType = DurationType.NOTCOMPLETE
    }
  }

  get playButtonIcon() {
    return (this.isPlaying ? 'pause' : 'play') + '_circle_outline'
  }

  get podcast() {
    return this.currentPodcast
  }

  get podcastPreviewImage() {
    return this.currentPodcast.imageUrl ? this.currentPodcast.imageUrl : null
  }

  get youtubeAutomation() {
    return this.podcast?.automations.length
      ? this.podcast.automations.filter((obj: any) => obj.type === 'youtube')[0]
      : false
  }

  hmsToSeconds(t: string) {
    const [hours, minutes, seconds] = t.split(':')
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
  }

  convertHHMMSStoSecs(duration: string) {
    const [hours, minutes, seconds] = duration.split(':')

    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
  }

  formatSizeDuration(e: any, input: string) {
    let elementRef, element, maxlength
    if (input === 'begin') {
      elementRef = this.$refs.beginRef
      element = this.beginTime
      maxlength = 8
    } else if (input === 'end') {
      elementRef = this.$refs.endRef
      element = this.endTime
      maxlength = 8
    } else if (input === 'width') {
      elementRef = this.$refs
      element = this.width.toString()
      maxlength = 4
    } else {
      elementRef = this.$refs
      element = this.height.toString()
      maxlength = 4
    }
    if (
      element.length < maxlength &&
      (e.keyCode == 8 || // backspace
        e.keyCode == 46 || // delete
        (e.keyCode >= 35 && e.keyCode <= 40) || // arrow keys/home/end
        (e.keyCode >= 48 && e.keyCode <= 57) || // numbers on keyboard
        (e.keyCode >= 96 && e.keyCode <= 105) || // number on keypad
        e.keyCode == 9) //Tab for keyboard accessibility
    ) {
      if (input != 'width' && input != 'height' && e.keyCode != 8 && (element.length === 2 || element.length === 5)) {
        let colon = (element += ':')
        // @ts-ignore
        Vue.set(elementRef, 'value', colon)
      }
    } else {
      if (
        element.length == maxlength &&
        (e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 46 || (e.keyCode >= 35 && e.keyCode <= 40))
      ) {
      } else {
        e.preventDefault() // Prevent character input
      }
    }
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  enhance() {
    this.podcastEnhance(true)
  }

  mounted() {
    if (isEmpty(this.selectedPodcastSetting)) {
      this.ytPodcastModalSetting({
        fullDuration: get(this.youtubeAutomation, 'defaultDuration') ? false : true,
        title: this.podcast.name,
        description: '',
        thumbnailSrc: this.podcast.imageUrl,
        startTimeSec: 0,
        endTimeSec: get(this.youtubeAutomation, 'defaultDuration', 1200) || 1200,
        category: get(this.youtubeAutomation, 'preferences.ytCategoryId', 24),
        width: get(this.youtubeAutomation, 'videoWidth', 1280) || 1280,
        height: get(this.youtubeAutomation, 'videoHeight', 720) || 720,
        keywords: get(this.youtubeAutomation?.preferences, 'keywords', ['podcast']),
        episodeKeywords: [],
        urlChapterMarkers: '',
        chapterMarkers: '',
        customTitle: get(this.youtubeAutomation, 'customTitle', '') || '',
        newDescription: get(this.youtubeAutomation, 'customDescription', '') || '',
        customDescription: false,
        includeVisuals: false,
        includeChapters: false,
        privacy: get(this.youtubeAutomation, 'preferences.privacy', 'public') || 'public',
        publish: 'Immediately',
        scheduledFor: '',
        isScheduled: false,
        scheduleDate: '',
        scheduleTime: '',
        timezone: '',
        hasTags: false,
        isYTShorts: get(this.youtubeAutomation, 'preferences.isShorts', false) || false,
        shortsCriteria: false,
        hasAudiograms: get(this.youtubeAutomation, 'audiogramId') ? true : false,
        isMadeForKids: get(this.youtubeAutomation, 'preferences.isMadeForKids', null),
        playlistId: get(this.youtubeAutomation, 'preferences.playlistId', null),
      })
    }
    this.selectedPodcastSetting.hasAudiograms &&
      this.selectedAudiograms.length === 0 &&
      this.$store.commit('selectAudiogram', get(this.youtubeAutomation, 'audiogramId'))
    this.beginTime = `${new Date(this.selectedPodcastSetting.startTimeSec * 1000).toISOString().substr(11, 8)}`
    this.endTime = new Date(this.selectedPodcastSetting.endTimeSec * 1000).toISOString().substr(11, 8)
    // this.customDuration = `${this.selectedPodcastSetting.endTimeSec / 60}`
    this.width = this.selectedPodcastSetting.width
    this.height = this.selectedPodcastSetting.height
    this.toggleValue = !this.selectedPodcastSetting.fullDuration
  }
}
