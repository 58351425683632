var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-wrap justify-between items-center pv3 link b--adori-gray bg-adori-gray br2 relative mb2",
    },
    [
      _c("div", { staticClass: "flex items-center justify-evenly w-100" }, [
        _c(
          "div",
          {
            staticClass:
              "w-10 flex dim pointer items-center ml4 relative img-div",
            on: { click: _vm.imageClicked },
          },
          [
            _c("img", {
              staticClass: "br2",
              attrs: {
                src: _vm.podcastPreviewImage,
                alt: "",
                width: "65",
                height: "65",
              },
            }),
            _c(
              "i",
              {
                staticClass:
                  "material-icons cam light-gray pointer dim f6 image-icon absolute",
              },
              [_vm._v(" photo_camera ")]
            ),
          ]
        ),
        _c("div", { staticClass: "flex flex-column justify-between w-20" }, [
          _c(
            "div",
            {
              staticClass: "f6 overflow-y-hidden w-90 truncate light-gray ttc",
              attrs: { title: _vm.podcast.name },
            },
            [
              _c("span", { staticClass: "gray" }, [_vm._v("Title:")]),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.podcast.name
                      ? _vm.podcast.name
                      : "Loading podcast details..."
                  ) +
                  "\n      "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "f6 overflow-y-hidden w-90 truncate light-gray mt3",
            },
            [
              _c("span", { staticClass: "gray" }, [_vm._v("Keywords:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.keywords ? _vm.keywords : "No Keywords") +
                  "\n      "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "flex flex-column w-20" }, [
          _c(
            "div",
            { staticClass: "f6 overflow-y-hidden w-90 light-gray ml3" },
            [
              _c("span", { staticClass: "gray" }, [_vm._v("Size:")]),
              _vm._v("\n        " + _vm._s(_vm.size) + "\n      "),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "f6 overflow-y-hidden w-90 truncate light-gray ml3 mt3",
            },
            [
              _c("span", { staticClass: "gray" }, [_vm._v("Duration:")]),
              _vm._v(
                "\n        " +
                  _vm._s(!_vm.fullDuration ? _vm.duration : "Full length") +
                  "\n      "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "flex flex-column w-20" }, [
          _c(
            "div",
            { staticClass: "f6 overflow-y-hidden w-90 light-gray ml3" },
            [
              _c("span", { staticClass: "gray" }, [_vm._v("Publish:")]),
              _vm._v("\n        " + _vm._s(_vm.publish) + "\n      "),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "f6 overflow-y-hidden w-90 truncate light-gray ml3 mt3",
            },
            [
              _c("span", { staticClass: "gray" }, [_vm._v("Include Visuals:")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.includeVisuals ? "Yes" : "No") +
                  "\n      "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "flex flex-column w-20 mr3" }, [
          _c(
            "div",
            {
              staticClass:
                "f6 overflow-y-hidden w-90 truncate light-gray ml3 ttc",
            },
            [
              _c("span", { staticClass: "gray" }, [_vm._v("Privacy:")]),
              _vm._v("\n        " + _vm._s(_vm.privacy) + "\n      "),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "f6 overflow-y-hidden w-90 truncate light-gray ml3 mt3",
            },
            [
              _c("span", { staticClass: "gray" }, [_vm._v("Category:")]),
              _vm._v("\n        " + _vm._s(_vm.category) + "\n      "),
            ]
          ),
        ]),
        _c("div", { staticClass: "flex justify-between items-center w-10" }, [
          _vm.podcast
            ? _c(
                "div",
                {
                  staticClass: "flex justify-between pl4 pointer dim",
                  on: { click: _vm.editPublish },
                },
                [
                  _c("i", { staticClass: "material-icons light-gray f4" }, [
                    _vm._v("edit"),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }