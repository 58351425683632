var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-adori-gray w-100 flex justify-start br2 mb3" },
    [
      _c("div", { staticClass: "w-10 mr2 br2 image-container" }, [
        _c("img", { attrs: { src: _vm.podcastPreviewImage, alt: "" } }),
      ]),
      _c(
        "div",
        { staticClass: "name flex items-center justify-center pa4 truncate" },
        [
          _c("span", { attrs: { title: _vm.podcast.name } }, [
            _vm._v(
              _vm._s(
                _vm.podcast
                  ? _vm.truncate(_vm.podcast.name, 25)
                  : "Loading podcast details..."
              )
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider mr2" }),
      _c(
        "div",
        {
          staticClass: "flex flex-column justify-center items-center w-25",
          attrs: { id: "edit_audio_tour_step_2" },
        },
        [
          _c("TheToggleButton", {
            staticClass: "mb1",
            attrs: {
              checkedText: "Custom Duration",
              uncheckedText: "Full Duration",
              value: _vm.toggleValue,
              dotColor: "red",
              "checked-bg": "gray",
              uncheckedBg: "gray",
              height: "30",
              width: "130",
            },
            on: {
              click: function ($event) {
                _vm.toggleValue = !_vm.toggleValue
              },
            },
          }),
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              {
                staticClass: "flex flex-column items-center justify-center mr2",
              },
              [
                _c("label", { staticClass: "mb1" }),
                _c(
                  "date-picker",
                  {
                    staticClass: "adori-date-picker",
                    staticStyle: { width: "100px" },
                    attrs: {
                      format: "HH:mm:ss",
                      "value-type": "format",
                      type: "time",
                      disabled: !_vm.toggleValue,
                      placeholder: "HH:MM:SS",
                      "popup-style": { top: "100%", left: 0 },
                      "time-select-options": _vm.timeSelectOptions,
                    },
                    model: {
                      value: _vm.endTime,
                      callback: function ($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime",
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons light-gray f4",
                        attrs: { slot: "calendar-icon" },
                        slot: "calendar-icon",
                      },
                      [_vm._v("schedule")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "flex flex-column center mt1 pr2" }, [
            _vm._v(
              "Duration: " +
                _vm._s("" + (_vm.toggleValue ? _vm.duration : "Full length"))
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "divider mr2" }),
      _c(
        "div",
        { staticClass: "mr2 w-30", attrs: { id: "edit_audio_tour_step_3" } },
        [
          _c(
            "div",
            { staticClass: "flex pa2 w-100 justify-center" },
            [
              _c("div", { staticClass: "flex flex-column w-50 ml4 mb1" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Width")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.width,
                      expression: "width",
                    },
                  ],
                  staticClass: "input bg-adori-very-light-gray",
                  attrs: {
                    type: "number",
                    placeholder: "1280",
                    max: "3840",
                    min: "426",
                    required: "",
                  },
                  domProps: { value: _vm.width },
                  on: {
                    keydown: function ($event) {
                      return _vm.formatSizeDuration($event, "width")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.width = $event.target.value
                    },
                  },
                }),
              ]),
              _c("span", { staticClass: "cross" }, [_vm._v("X")]),
              _c("div", { staticClass: "flex flex-column w-50" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Height")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.height,
                      expression: "height",
                    },
                  ],
                  staticClass: "input bg-adori-very-light-gray",
                  attrs: {
                    type: "number",
                    placeholder: "720",
                    max: "2160",
                    min: "240",
                    required: "",
                  },
                  domProps: { value: _vm.height },
                  on: {
                    keydown: function ($event) {
                      return _vm.formatSizeDuration($event, "height")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.height = $event.target.value
                    },
                  },
                }),
              ]),
              _c("BasePopupMenu", {
                staticClass: "dropdown",
                attrs: {
                  icon: "arrow_drop_down",
                  items: _vm.videoResolutionMenu,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "flex justify-center" }, [
            _c(
              "div",
              {
                staticClass: "bn w-46 h-46 landscape bg-center pointer mr1 pv2",
                class: { isActive: _vm.active === "landscape" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActive("landscape")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "w-100 h-100 bg-adori-very-light-gray",
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "bn w-46 h-46 square bg-center pointer pa2",
                class: { isActive: _vm.active === "square" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActive("square")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "w-100 h-100 bg-adori-very-light-gray",
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "bn w-46 h-46 portrait bg-center pointer ph2",
                class: { isActive: _vm.active === "portrait" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActive("portrait")
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "w-100 h-100 bg-adori-very-light-gray",
                }),
              ]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider mr2" }),
      _c(
        "div",
        {
          staticClass:
            "flex flex-column items-center justify-center w-15 dim mobile-hide pointer",
          attrs: { id: "edit_audio_tour_step_4" },
          on: { click: _vm.enhance },
        },
        [
          _c("label", [_vm._v("Enhance")]),
          _c(
            "i",
            { staticClass: "material-icons dropdown light-gray center f2" },
            [_vm._v(" graphic_eq ")]
          ),
          _vm.selectedAudiograms.length
            ? _c(
                "div",
                { staticClass: "flex justify-center items-center f7 mt4" },
                [
                  _c("div", { staticClass: "mr2" }, [
                    _vm._v(_vm._s(_vm.selectedAudiograms.length) + " "),
                  ]),
                  _c(
                    "i",
                    { staticClass: "material-icons light-gray center f7" },
                    [_vm._v(" local_offer ")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }