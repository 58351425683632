var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showPodcastEnhance,
            expression: "!showPodcastEnhance",
          },
        ],
      },
      [
        _c("TheHeader"),
        _c("div", { staticClass: "flex flex-wrap" }, [
          _c("div", { staticClass: "custom-limiter scrollbar mt4" }, [
            _vm.showHomeLink
              ? _c(
                  "div",
                  {
                    staticClass: "flex light-gray pointer w3 dim",
                    on: { click: _vm.goToHome },
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
            _vm.automated
              ? _c("div", [
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "flex justify-center items-center mt5" },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.currentPodcast.imageUrl,
                          alt: "",
                          width: "150",
                          height: "150",
                        },
                      }),
                      _c(
                        "i",
                        {
                          staticClass: "material-icons white ph5",
                          staticStyle: { "font-size": "58px" },
                        },
                        [_vm._v("arrow_forward")]
                      ),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/youtube/no_text_youtube.png"),
                          alt: "",
                          width: "150",
                          height: "120",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex justify-center items-center mt5" },
                    [
                      _c("BaseButtonBorder", {
                        attrs: {
                          icon: "home",
                          text: "Home",
                          onClick: _vm.goToHome,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "relative w-100 flex justify-center" },
                    [
                      _c("StepperComponent", {
                        attrs: {
                          step: _vm.autoPublishStep,
                          stepsLabel: _vm.stepsLabel,
                        },
                        on: { changeStep: _vm.changeStep },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-100 flex mt3" },
                    [
                      _vm.autoPublishStep === 1
                        ? _c("BaseButtonRed", {
                            staticClass: "next",
                            attrs: { text: "Next", onClick: _vm.nextStep },
                          })
                        : _vm._e(),
                      _vm.autoPublishStep === 2
                        ? _c("BaseButtonRed", {
                            staticClass: "next",
                            attrs: {
                              icon: _vm.publishIcon,
                              text: _vm.PublishText,
                              loading: _vm.youtubeLoader,
                              disabled: _vm.youtubeLoader,
                              onClick: _vm.handleBeforePublish,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "w-100 flex mt2 white" }, [
                    _vm._v(
                      "\n            All your new episodes will get automatically published to youtube with parameters selected\n          "
                    ),
                  ]),
                  _vm.autoPublishStep === 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bg-adori-light-gray br2 light-gray ba b--adori-gray pa4 mt3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "episode-list scrollbar" },
                            [_c("AutoPublishStepOne")],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.autoPublishStep === 2
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bg-adori-light-gray br2 light-gray ba b--adori-gray pa4 mt3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mt3 episode-list scrollbar" },
                            [_c("AutoPublishStepTwo")],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "w-100 flex mt3" },
                    [
                      _c("BaseButtonBorder", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.autoPublishStep !== 1,
                            expression: "autoPublishStep !== 1",
                          },
                        ],
                        attrs: { text: "Previous", onClick: _vm.previousStep },
                      }),
                      _vm.autoPublishStep === 1
                        ? _c("BaseButtonRed", {
                            staticClass: "next",
                            attrs: {
                              loading: _vm.nextStepDisabled,
                              text: "Next",
                              onClick: _vm.nextStep,
                            },
                          })
                        : _vm._e(),
                      _vm.autoPublishStep === 2
                        ? _c("BaseButtonRed", {
                            staticClass: "next",
                            attrs: {
                              icon: _vm.publishIcon,
                              loading: _vm.youtubeLoader,
                              disabled: _vm.youtubeLoader,
                              text: _vm.PublishText,
                              onClick: _vm.handleBeforePublish,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPodcastEnhance,
            expression: "showPodcastEnhance",
          },
        ],
        staticClass: "custom-limiter scrollbar mt4",
      },
      [
        _c(
          "div",
          { staticClass: "flex items-center justify-between mt2 white" },
          [
            _c("div", [_vm._v("Select an audiogram")]),
            _c("BaseButtonRed", { attrs: { text: "Done", onClick: _vm.done } }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "bg-adori-light-gray br2 light-gray ba b--adori-gray pa4 mt3",
          },
          [
            _c("TheAudiogramGrid", {
              attrs: { orientation: _vm.filter, autoPublish: "" },
              on: { setFilter: _vm.setFilter },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex" }, [
      _c("i", { staticClass: "material-icons v-mid" }, [_vm._v(" home ")]),
      _c("div", { staticClass: "ml1 mt-auto" }, [_vm._v("Home")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-column items-center white mt5" },
      [
        _c("div", { staticClass: "f2" }, [
          _vm._v("Yay! Your Automation is on"),
        ]),
        _c("div", { staticClass: "f4 mt3" }, [
          _vm._v(
            "Now sit back and relax, your upcoming episodes will be published to youtube"
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }